<template>
    <div :style="{ height: screeHeight + 'px' }" style="background-color: rgb(239 239 239);">
        <div style="height: 180px;">
            <img src="../../assets/personback.png" alt="" style="width:100%;position: absolute;">
            <div style="height: 120px;;border-radius: 0 0 15px 15px;position: relative;padding-top: 35px;">
                <van-row>
                    <van-col span="6" style="text-align: center;">
                        <img v-if="(!isLogin || !user.avatar || user.avatar == '')" src="../../assets/avt.png" alt="" style="width: 70px;">
                        <img v-else :src="user.avatar" alt="" style="width: 75px;">
                    </van-col>
                    <div v-if="user.mobile" style="color: #FFFFFF;margin-top: 30px;padding-top: 10px;">
                        <div style="font-size: 19px;font-weight: bold;">{{user.name}}</div>
                        <div style="padding: 8px 0; display: flex;">
                            <img style="width: 22px;height: 21px;padding-right: 2px;" src="../../assets/zuanshi.png"/>
                                {{user.levelName || '会员权益'}}
                            <div style="padding-left: 18px;" @click="$router.push('/personal/personalInfo')">
                                <img style="width: 22px;height: 20px;" src="../../assets/geren.png"/>
                            </div>
                            个人中心
                        </div>
                    </div>
                    <!-- <van-col v-if="user.mobile" span="16" style="margin-top: 35px;color: white;">
                        <div style="font-size: 19px;">{{user.name}}</div>
                        <div style="padding: 8px 0;">
                            <span><img style="width: 15px;" src="../../assets/zuanshi.png"/><span>{{user.levelName || '会员权益'}}</span></span>
                            <span style="padding-left: 15px;" @click="$router.push('/personal/personalInfo')">
                                <img style="width: 16px;" src="../../assets/geren.png"/>
                                <span>个人中心</span></span>
                        </div>
                    </van-col>-->
                    <div v-else style="line-height: 120px;color: white;font-weight: bold;">暂未登录</div> 
                </van-row>
            </div>
        </div>
        <div style="margin: 5px;">
            <van-row style="margin: 15px 0;">
                <van-cell value="全部订单" is-link @click="$router.push({path:'/order',query:{active:0}})">
                    <template #title>
                        <span style="font-size: 20px;font-weight: bold;font-family: '宋体';">我的订单</span>
                    </template>
                </van-cell>
                <van-grid>
                    <van-grid-item text="待付款" @click="$router.push({path:'/order',query:{active:1}})">
                        <template #icon>
                            <img src="../../assets/daifukuan.png" alt="" style="width:43px">
                        </template>
                    </van-grid-item>
                    <van-grid-item text="待使用" @click="$router.push({path:'/order',query:{active:2}})" >
                        <template #icon>
                            <img src="../../assets/daishiyong.png" alt="" style="width:41px">
                        </template>
                    </van-grid-item>
                    <van-grid-item text="待评价" @click="$router.push({path:'/order',query:{active:3}})">
                        <template #icon>
                            <img src="../../assets/daipingjia.png" alt="" style="width:42px">
                        </template>
                    </van-grid-item>
                    <van-grid-item text="退款/售后" @click="$router.push({path:'/order',query:{active:4}})" >
                        <template #icon>
                            <img src="../../assets/shouhou.png" alt="" style="width:43px">
                        </template>
                    </van-grid-item>
                </van-grid>
                <van-cell style="margin-top: 10px;#333333" @click="$router.push('/personal/personalInfo')" title="账号信息" is-link >
                    <template #icon>
                        <img src="../../assets/item.png" alt="" style="width: 24px;padding-right: 10px;">
                    </template>
                </van-cell>
                <van-cell title="常用出游人" @click="$router.push('/personal/chuyouren')" is-link >
                    <template #icon>
                        <img src="../../assets/concat.png" alt="" style="width: 25px;padding-right: 10px;">
                    </template>
                </van-cell>
            </van-row>
        </div>
    </div>
</template>
<script>

export default {
  name: 'info',
  data(){
        return {
            screenWidth: document.documentElement.clientWidth,     // 屏幕宽
            screeHeight: document.documentElement.clientHeight,    // 屏幕高
            info:'',
            active:0,
            user:this.$store.state.user,
            isLogin:this.$store.state.successLogin
        }
    },
    mounted(){
        // this.user = {name:'张三三',mobile:'15897446413465'}
        // this.getInfo()
    },
    methods:{
        getInfo(){
            this.$http({
                method: "post",
                url: `${this.apis.getUserInfo}?token=a0afb671c43f4e8d96a8ca92e2523377&orgId=${this.mydata.orgId}`,
            }).then((res) => {
                if (res.code == 200) {
                    this.user = res.data
                    this.$store.commit('saveUser',res.data)
                }
            })
            
        },
        toSearch(){
            this.onSearch()
            this.isSearch = true
        },
        onSearch(){
            this.isSearch = false
            if(this.searchValue == ''){
                this.seaList = []
                return
            }
            this.seaList = this.list.filter(ite => ite.name.includes(this.searchValue))
        },
        keyRegExp(val, keyword) {
            val = val + '';
            if (val.indexOf(keyword) !== -1 && keyword !== '') {
                return val.replace(keyword, '<font color="#f00">' + keyword + '</font>')
            } else {
                return val
            }
        },
    }

}
</script>
<style scoped>
.active{
    width: 70px;
    background-color: #fd1a34;
    color: white;
    text-align: center;
    border-radius: 15px;
    padding: 3px;
}
.nactive{
    width: 70px;
    padding: 3px;
    text-align: center;
    border-radius: 15px;
}
</style>